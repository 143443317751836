












































































































import LazyHydrate from 'vue-lazy-hydration';
import {
  SfLoader, SfPagination, SfProductCard, SfSelect, SfTabs
} from '~/components';
import {
  computed,
  defineComponent,
  onMounted,
  useRouter,
  useRoute,
  useContext,
} from '@nuxtjs/composition-api';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import wishlistGetters from '~/modules/wishlist/getters/wishlistGetters';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import EmptyWishlist from '~/modules/wishlist/components/EmptyWishlist.vue';
import { ProductTypeEnum } from '~/modules/catalog/product/enums/ProductTypeEnum';
import { useUiHelpers, useImage, useProduct } from '~/composables';

export default defineComponent({
  name: 'MyWishlist',
  components: {
    SfLoader,
    SfTabs,
    SfProductCard,
    SfPagination,
    SfSelect,
    EmptyWishlist,
    LazyHydrate,
  },
  setup() {
    const {
      load,
      loading,
      removeItem,
      afterAddingWishlistItemToCart,
    } = useWishlist();
    const route = useRoute();
    const { localeRoute } = useContext();
    const { getProductPath } = useProduct();
    const {
      query: { page, itemsPerPage },
    } = route.value;
    const router = useRouter();
    const th = useUiHelpers();
    const {
      addItem: addItemToCartBase,
      error: cartError,
      isInCart,
      loading: isCartLoading,
    } = useCart();
    const wishlistStore = useWishlistStore();

    const products = computed(() => wishlistGetters.getProducts(wishlistStore.wishlist));
    const pagination = computed(() => wishlistGetters.getPagination(wishlistStore.wishlist));

    const addItemToCart = async ({ product, quantity }: { product: Product, quantity: number }) => {
      // eslint-disable-next-line no-underscore-dangle
      const productType = product.__typename;

      switch (productType) {
        case ProductTypeEnum.SIMPLE_PRODUCT:
          await addItemToCartBase({
            product,
            quantity,
          });
          afterAddingWishlistItemToCart({
            product,
            cartError: cartError.value.addItem,
          });
          break;
        case ProductTypeEnum.CONFIGURABLE_PRODUCT:
        case ProductTypeEnum.BUNDLE_PRODUCT:
        case ProductTypeEnum.GROUPED_PRODUCT:
          await router.push(localeRoute(getProductPath(product)));
          break;
        default:
          throw new Error(
            `Product Type ${productType} not supported in add to cart yet`,
          );
      }
    };

    const removeItemFromWishlist = async (product: Product) => {
      await removeItem({ product });
    };

    const { getMagentoImage, imageSizes } = useImage();

    onMounted(async () => {
      await load({
        searchParams: {
          currentPage: page ? Number.parseInt(page.toString(), 10) : 1,
          pageSize: itemsPerPage ? Number.parseInt(itemsPerPage.toString(), 10) : 10,
        },
      });
    });

    return {
      addItemToCart,
      removeItemFromWishlist,
      isInCart,
      isCartLoading,
      loading,
      pagination,
      productGetters,
      products,
      th,
      getMagentoImage,
      imageSizes,
      getProductPath,
    };
  },
});
