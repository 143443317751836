




















import {defineComponent, useContext, useRouter} from '@nuxtjs/composition-api';
import { SfButton, SfHeading } from '~/components';

export default defineComponent({
  name: 'EmptyWishlist',
  components: {
    SfButton,
    SfHeading,
  },
  setup() {
    const router = useRouter();
    const { localeRoute } = useContext();

    const goToHome = () => router.push(localeRoute({ name: 'home' }));

    return { goToHome }
  }
});
